import {TweenMax} from 'gsap';
import Swiper from 'swiper';
import Testimonies from './../components/testimonies';
import Wheel from './../components/wheel';

export default class Services {
    constructor() {
        // Page services
        this.slides = Array.from(document.querySelectorAll('section article .experts .swiper-container .swiper-slide'));
        this.locations = {};

        new Testimonies();
        new Wheel();

        if (document.querySelector('section article .interventions .wrapper .tabs')) {
            TweenMax.to(
                'section article .interventions .wrapper .tabs',
                .3,
                {
                    height: document.querySelector('section article .interventions .wrapper .tabs .active').offsetHeight,
                },
            );
        }

        const expertsSlider = new Swiper('.experts .swiper-container', {
            loop: false,
            slidesPerView: 3,
            spaceBetween: 20,
            autoplay: {
                delay: 5000,
            },
            breakpoints: {
                1023: {
                    slidesPerView: 2,
                },
                767: {
                    slidesPerView: 1,
                },
            },
            navigation: {
                nextEl: '.experts .controls .next',
                prevEl: '.experts .controls .prev',
            },
        });

        const interventionsSlider = new Swiper('.interventions .wrapper .swiper-container', {
            loop: false,
            freeMode: true,
            navigation: {
                nextEl: '.interventions .wrapper .controls .next',
                prevEl: '.interventions .wrapper .controls .prev',
            },
            on: {
                init: () => {
                    document.querySelector('.interventions .wrapper .controls .prev').style.opacity = '.5';
                },
            },
        });

        interventionsSlider.on('slideChange', () => {
            if (interventionsSlider.activeIndex == 0) {
                document.querySelector('.interventions .wrapper .controls .prev').style.opacity = '.5';
            } else {
                document.querySelector('.interventions .wrapper .controls .prev').style.opacity = '1';
            }

            if (interventionsSlider.activeIndex == interventionsSlider.slides.length - 1) {
                document.querySelector('.interventions .wrapper .controls .next').style.opacity = '.5';
            } else {
                document.querySelector('.interventions .wrapper .controls .next').style.opacity = '1';
            }
        });

        if (window.innerWidth > 1025) {
            Array.from(document.querySelectorAll('section article .interventions .wrapper > ul li')).forEach(elt => {
                elt.addEventListener('click', e => {
                    const i = e.currentTarget.dataset.index;
                    Array.from(document.querySelectorAll('section article .interventions .wrapper .tabs .tab')).forEach(element => {
                        element.classList.remove('active');
                    });
                    document.querySelector('section article .interventions .wrapper .tabs').children[i].classList.add('active');
                    document.querySelector('section article .interventions .wrapper > ul li.active').classList.remove('active');
                    e.currentTarget.classList.add('active');
                    TweenMax.to(
                        'section article .interventions .wrapper .tabs',
                        .3,
                        {
                            height: document.querySelector('section article .interventions .wrapper .tabs').children[i].offsetHeight,
                            onComplete: function () {
                                TweenMax.from(
                                    'section article .interventions .wrapper .tabs .tab.active .progress svg path',
                                    .75,
                                    {
                                        css: {
                                            'stroke-dashoffset': '312px',
                                        },
                                    },
                                );
                            },
                        },
                    );

                });
                elt.addEventListener('touchstart', e => {
                    const i = e.currentTarget.dataset.index;
                    Array.from(document.querySelectorAll('section article .interventions .wrapper .tabs .tab')).forEach(element => {
                        element.classList.remove('active');
                    });
                    document.querySelector('section article .interventions .wrapper .tabs').children[i].classList.add('active');
                    document.querySelector('section article .interventions .wrapper > ul li.active').classList.remove('active');
                    e.currentTarget.classList.add('active');
                    TweenMax.to(
                        'section article .interventions .wrapper .tabs',
                        .3,
                        {
                            height: document.querySelector('section article .interventions .wrapper .tabs').children[i].offsetHeight,
                            onComplete: function () {
                                TweenMax.from(
                                    'section article .interventions .wrapper .tabs .tab.active .progress svg path',
                                    .75,
                                    {
                                        css: {
                                            'stroke-dashoffset': '312px',
                                        },
                                    },
                                );
                            },
                        },
                    );

                });
            });
        } else {

            interventionsSlider.on('slideChange', () => {
                const i = interventionsSlider.activeIndex;
                Array.from(document.querySelectorAll('section article .interventions .wrapper .tabs .tab')).forEach(element => {
                    element.classList.remove('active');
                });
                document.querySelector('section article .interventions .wrapper .tabs').children[i].classList.add('active');

                TweenMax.to(
                    'section article .interventions .wrapper .tabs',
                    .1,
                    {
                        height: document.querySelector('section article .interventions .wrapper .tabs').children[i].offsetHeight,
                        onComplete: function () {
                            var options = {
                                root: null,
                                rootMargin: '0px',
                                threshold: 0.8,
                            };

                            let observer = new IntersectionObserver(callback, options);

                            function callback(entries) {
                                entries.forEach(function (entry) {
                                    if (entry.isIntersecting) {
                                        observer.disconnect();

                                        if (entry.target.parentElement.parentElement.parentElement.classList.contains('active')) {

                                            TweenMax.from(
                                                entry.target.children[0],
                                                .75,
                                                {
                                                    css: {
                                                        'stroke-dashoffset': '314px',
                                                    },
                                                    delay: 0.25,
                                                },
                                            );
                                        }
                                    }
                                });
                            }

                            function obs(targets) {
                                targets.forEach((target) => observer.observe(target));
                            }

                            let targetsInterventions = Array.from(document.querySelectorAll('section article .interventions .wrapper .tabs .tab .progress svg'));

                            obs(targetsInterventions);
                        },
                    },
                );
            });
        }

        const filters = Array.from(document.querySelectorAll('section article .experts .filters li'));
        let selectedLocation = null;
        filters.forEach(listItem => {
            listItem.addEventListener('click', () => {
                // Click on the already active filter
                if (selectedLocation === listItem.dataset.location) {
                    selectedLocation = null;
                    filters.forEach(li => li.classList.remove('active'));
                    expertsSlider.slides.each((index, slide) => slide.style.display = '');
                    expertsSlider.update();
                    return;
                }

                selectedLocation = listItem.dataset.location;
                // Add active class on clicked element and remove it for the others
                filters.forEach(li => li.classList.toggle('active', listItem === li));
                // Show/hide slides
                expertsSlider.slides.each((index, slide) => {
                    slide.style.display = slide.dataset.location === selectedLocation ? '' : 'none';
                });
                expertsSlider.update();
            });
        });

        let push = document.querySelector('section article .two-cols .push');
        if (push) {
            push.addEventListener('click', () => {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
            });

            let section = document.querySelector('section');

            window.addEventListener('scroll', function () {
                if (window.pageYOffset > section.offsetTop) {
                    push.style.position = 'fixed';
                    push.style.right = 0;

                } else {
                    push.style.position = 'absolute';
                    push.style.right = 'calc((100vw - 1024px) / 2 * -1 - 10% - 11px)';
                }
            });
        }
    }

    msieversion() {
        var ua = window.navigator.userAgent;
        var msie = ua.indexOf('MSIE ');

        if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
            return true;
        } else {
            return false;
        }
    }
}
