import {TweenMax, TweenLite} from 'gsap';

export default class Localisation {
    constructor() {
        // Page localisation

        let cards = Array.from(document.querySelectorAll('.details .card'));
        let anchors = Array.from(document.querySelectorAll('section article svg .cities a'));
        let titles = Array.from(document.querySelectorAll('.details .title'));

        if(window.innerWidth > 1023){
            document.querySelector('section .details .card#toulouse').classList.add('visible');
            document.querySelector('section article svg .cities a[href="#toulouse"]').classList.add('active');

            anchors.forEach(anchor => anchor.addEventListener('click', event => {
                event.preventDefault();
                cards.forEach(card => {
                    card.classList.toggle('visible', card.id === anchor.href.baseVal.substr(1));
                });
                anchors.forEach(a => a.classList.toggle('active', a === anchor));
            }));
        } else {
            titles.forEach(title => {
                title.nextElementSibling.dataset.height = title.nextElementSibling.offsetHeight;
                title.nextElementSibling.style.height = '0px';
                title.classList.remove('open');

                title.addEventListener('click', e => {
                    titles.forEach(t => {
                        if(t == title) {
                            if(t.nextElementSibling.style.height == '0px' || t.nextElementSibling.style.height == ''){
                                t.classList.add('open');
                                TweenMax.to(
                                    t.nextElementSibling,
                                    .3,
                                    {
                                        height: title.nextElementSibling.dataset.height + 'px'
                                    }
                                );
                            } else {
                                t.classList.remove('open');
                                TweenMax.to(
                                    t.nextElementSibling,
                                    .3,
                                    {
                                        height: '0px'
                                    }
                                );
                            }
                            
                        } else {
                            t.classList.remove('open');
                            TweenMax.to(
                                t.nextElementSibling,
                                .3,
                                {
                                    height: '0px'
                                }
                            );
                        }
                    });
                });
            });
        }
    }
}