import TweenMax from 'gsap/src/uncompressed/TweenMax';
import Swiper from 'swiper';

export default class Wheel {
    constructor() {
        // Module Wheel
        this.services = Array.from(document.querySelectorAll('.services .service'));
        this.hover = document.querySelector('.services .title span');
        let title = document.querySelector('header article h1');
        let span_wheel = document.querySelector('.single-service .services .title span');
        if (span_wheel){
            span_wheel.innerText = title.innerText;
        }

        if (title){
            title = title.innerText;
        } else {
            title = '';
        }

        if (document.body.classList.contains('single-service') && window.pageYOffset
            === 0) {
            setTimeout(() => {
                window.scrollTo({
                    top: document.querySelector('.single-service header').offsetTop - document.querySelector('nav').offsetHeight,
                    behavior: 'smooth',
                });
            }, 1000);
        }

        if(this.msieversion()){
            this.svgPosition();
        }

        const svg2 = document.querySelector('.services svg .circle-logo path');
        if(svg2){
            svg2.setAttribute( 'transform', 'translate(347, 288)' );
        }

        this.services.forEach(elt => {
            if(window.innerWidth > 1023){
                elt.addEventListener('mouseenter', e => {
                    TweenMax.fromTo(this.hover, .3,
                        {
                            transform: 'translate(0, 100%)',
                            opacity: '0',
                        },
                        {
                            transform: 'translate(0, 0%)',
                            opacity: '1',
                        },
                    );
                    this.hover.innerText = Array.from(e.target.querySelectorAll('tspan'))
                        .map(tspan => tspan.textContent.trim())
                        .join(' ');

                    document.querySelector('.services').classList.add('hover');

                    TweenMax.to(
                        document.querySelector('.bg'),
                        .3,
                        {
                            attr: {
                                r: 320
                            }
                        }
                    );
                });
            }

            elt.addEventListener('mouseleave', () => {
                TweenMax.to(this.hover, .3, {
                    transform: 'translate(0, -100%)',
                    opacity: '0',
                    onComplete: () => {
                        this.hover.innerText = title;

                        TweenMax.fromTo(this.hover, .3, {
                            transform: 'translate(0, 100%)',
                            opacity: '0'
                        }, {
                            transform: 'translate(0, 0%)',
                            opacity: '1'
                        });
                    },
                });

                document.querySelector('.services').classList.remove('hover');
                TweenMax.to(
                    document.querySelector('.bg'),
                    .3,
                    {
                        attr: {
                            r: 260
                        }
                    }
                );
            });

            elt.addEventListener('click', (e) => {
                window.location.href = e.currentTarget.classList[1];
            });
        });

        this.mobile();
        window.addEventListener('resise', () => this.mobile());
    }

    managePosition(elt) {
        const idx = elt.dataset.index;
        const rotate = 360 / this.services.length * (this.services.length - idx);

        Array.from(this.services).forEach((element, j) => {
            let getRotation = -360 / this.services.length * j - rotate - 90;
            if (getRotation < -359) {
                getRotation += 360;
            }
            element.children[0].style.transform = 'rotate(' + getRotation + 'deg)';
        });

        if (document.body.classList.contains('single-service')) {
            elt.children[0].style.display = 'none';
        }
    }

    mobile() {
        let dashoffset = -969,
            step = 969 / 9;
        if (window.innerWidth < 768) {
            let swiper_a;
            let mobileWheel = new Swiper('.services-mobile', {
                loop: true,
                speed: 1300,
                slidesPerView: 1,
                centeredSlides: true,
                navigation: {
                    nextEl: '.button-next',
                    prevEl: '.button-prev',
                },
                on: {
                    init: function(){
                        document.querySelector('.services-mobile svg #progress').style.strokeDashoffset = '-969px';
                        document.querySelector('.services-mobile .more').href = document.querySelector('.services-mobile .dots').children[0].dataset.url;
                        document.querySelector('.services-mobile .svg .txt a').href = document.querySelector('.services-mobile .dots').children[0].dataset.url;
                        document.querySelector('.services-mobile .svg .txt span').innerText = document.querySelector('.services-mobile .swiper-slide-active a span').innerText;
                        var circle_url = document.querySelector('.services-mobile .svg svg .dots circle.active').dataset.url;
                        swiper_a = document.querySelector('.services-mobile .swiper-slide a[href="' + circle_url + '"]');

                        const index_value = parseInt(swiper_a.parentElement.dataset.index);
                        this.slideToLoop(index_value);
                        document.querySelector('.services-mobile svg #progress').style.strokeDashoffset = (dashoffset - step * this.realIndex) + 'px';

                    }
                }
            });

            mobileWheel.on('slideChange', () => {
                document.querySelector('.services-mobile svg #progress').style.strokeDashoffset = (dashoffset - step * mobileWheel.realIndex) + 'px';
                document.querySelector('.services-mobile .more').href = document.querySelector('.services-mobile .dots').children[mobileWheel.realIndex].dataset.url;
                document.querySelector('.services-mobile .svg .txt a').href = document.querySelector('.services-mobile .dots').children[mobileWheel.realIndex].dataset.url;
                document.querySelector('.services-mobile .more').classList.add('hover');
                setTimeout( () => {
                    document.querySelector('.services-mobile .more').classList.remove('hover');
                }, 1100);
                Array.from(document.querySelectorAll('.services-mobile .dots circle')).forEach(elt => {
                    elt.classList.remove('active');
                });

                document.querySelector('.services-mobile .dots').children[mobileWheel.realIndex].classList.add('active');
            });

            mobileWheel.on('slideChangeTransitionEnd', () => {
                document.querySelector('.services-mobile .svg .txt span').innerText = document.querySelector('.services-mobile .swiper-slide-active a span').innerText;
            });
        }
    }

    svgPosition() {
        const svg = document.querySelector('.services svg .circle-logo');
        svg.setAttribute( 'transform', 'matrix(.85, 0, 0, .85, 71.25, 60)' );
    }

    msieversion() {
        var ua = window.navigator.userAgent;
        var msie = ua.indexOf("MSIE ");

        if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)){
            return true;
        } else {
            return false;
        }
    }
}
