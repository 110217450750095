import Swiper from 'swiper';

export class Timeline {
    constructor(element) {
        this.rootElement = element;
        this.swiper = new Swiper(this.rootElement.querySelector('.swiper-container'), {
            //freeMode: true,
            slidesPerView: 'auto',
            slidesPerColumn: 1,
            breakpoints: {
                767: {
                   slidesPerView: 1,
                   slidesPerColumn: 1,
                   freeMode: false
                }
            },
        });
    }
}
