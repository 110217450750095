import Swiper from 'swiper';
import {Timeline} from '../components/Timeline';

export default class Recrutement {
    constructor() {
        // Page recrutement

        this.posSvg();
        this.intro();
        this.engagments();

        Array.from(document.querySelectorAll('.timeline'))
            .forEach(element => new Timeline(element));
    }

    posSvg() {
        const x = window.innerWidth / 2,
            y = (window.innerHeight - 99) / 2,
            h = 377,
            w = 383;

        document.querySelector('#myClip path').style.transform = 'translate(' + (x - w + w / 2) + 'px, ' + (y - h + h / 2) + 'px)';
    }

    intro() {
        const recrutementSlider = new Swiper ('.slider .swiper-container', {
            loop: true,
            spaceBetween: 20,
            speed:1300,
            autoplay: {
                delay: 4000
            },
            navigation: {
                nextEl: '.slider .controls .next',
                prevEl: '.slider .controls .prev',
            },
        });
    }

    engagments() {
        let engagments        = document.querySelectorAll('.engagement');
        let engagmentsDetails = document.querySelectorAll('.engagement-detail');

        Array.from(engagments).forEach(engagment => {
           engagment.addEventListener('click', () => {
               Array.from(engagmentsDetails).forEach( engagmentDetail => {
                   engagmentDetail.classList.toggle('active', engagmentDetail.id === engagment.dataset.id);
               });
               Array.from(engagments).forEach(engagmentItem => {
                   engagmentItem.classList.remove('active');
               });
               engagment.classList.add('active');
           });
        });
    }
}