export class Video {
    constructor(element) {
        this.rootElement = element;
        this.video = this.rootElement.querySelector('video');
        this.videoControls = this.video.controls;
        this.video.controls = false;

        this.createPlayButton();
        this.playButton.addEventListener('click', () => this.play());
    }

    createPlayButton() {
        this.playButton = document.createElement('div');
        this.playButton.classList.add('play');
        this.playButton.innerHTML = '<span></span>';
        this.rootElement.prepend(this.playButton);
    }

    play() {
        this.playButton.style.display = 'none';
        this.video.play();
        this.video.controls = this.videoControls;
    }
}