import objectFitImages from 'object-fit-images';
import Scroll from './components/scroll';
import {Youtubevideo} from './components/Youtubevideo';
import Contact from './renderers/contact';
import Home from './renderers/home';
import Hub from './renderers/hub';
import Localisation from './renderers/localisations';
import Recrutement from './renderers/recrutement';
import Rte from './renderers/rte';
import Services from './renderers/services';
import {Router} from './Router';
import {TweenMax, TweenLite} from 'gsap';

objectFitImages();

let router = new Router([
    {
        classes: 'home',
        controller: Home,
    },
    {
        classes: 'page-template-recruitment',
        controller: Recrutement,
    },
    {
        classes: ['single-service', 'post-type-archive-service'],
        controller: Services,
    },
    {
        classes: ['single', 'page'],
        controller: Rte,
    },
    {
        classes: 'post-type-archive-office',
        controller: Localisation,
    },
    {
        classes: 'post-type-archive-service',
        controller: Hub,
    },
]);

document.addEventListener('DOMContentLoaded', () => {
    router.start();

    new Youtubevideo();
    new Contact();

    document.querySelector('footer .show-popin').addEventListener('click', () => {
        document.querySelector('#popin').style.display = 'block';

        document.body.style.position = 'fixed';
        document.body.style.bottom = '0';
        document.body.style.overflow = 'hidden';
    });

    if(window.location.hash == '#footer'){
        document.querySelector('#popin').style.display = 'block';

        document.body.style.position = 'fixed';
        document.body.style.bottom = '0';
        document.body.style.overflow = 'hidden';
    }

    document.querySelector('#popin .overlay').addEventListener('click', () => {
        document.querySelector('#popin').style.display = 'none';

        document.body.style.position = '';
        document.body.style.bottom = '';
        document.body.style.overflow = '';

        history.replaceState(null, null, ' ');
    });

    document.querySelector('#popin .close').addEventListener('click', () => {
        document.querySelector('#popin').style.display = 'none';

        document.body.style.position = '';
        document.body.style.bottom = '';
        document.body.style.overflow = '';

        history.replaceState(null, null, ' ');
    });

    document.querySelector('nav .row .options .search').addEventListener('click', () => {
        document.querySelector('nav .row .search-container').style.display = 'block';
        document.querySelector('nav .row .search-container form input[name="s"]').focus();
    });

    document.querySelector('nav .row .search-container .close').addEventListener('click', (e) => {
        document.querySelector('nav .row .search-container').style.display = 'none';
    });

    document.querySelector('nav .row .options .burger').addEventListener('click', () => {
        document.querySelector('nav').classList.toggle('open');
    });

    const languageSelector = document.querySelector('nav .row .options .languages');
    if (languageSelector) {
        const list = languageSelector.querySelector('ul');
        languageSelector.querySelector('span').addEventListener('click', () => list.classList.toggle('visible'));
    }

    if (window.innerWidth < 1024) {
        Array.from(document.querySelectorAll('nav .row .nav .menu-item-has-children > a')).forEach(elt => {
            elt.addEventListener('click', e => {
                e.preventDefault();
                e.currentTarget.parentNode.classList.toggle('active');
            });
        });
    }

    const getTarte = document.querySelector('footer ul .toggle-tarte');
    if (getTarte) {
        document.querySelector('footer ul .toggle-tarte').addEventListener('click', e => {
            e.preventDefault();
            tarteaucitron.userInterface.openPanel();
        });
    }

    let scroll = new Scroll();
    scroll.init();

    let temoignages = document.querySelectorAll('.temoignages .wrapper .slider-temoignages .swiper-wrapper .swiper-slide');
    let controls_temoignages = document.querySelector('.temoignages .controls');


    var options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.35
    }

    let observer = new IntersectionObserver(intersectionCallback, options);

    function intersectionCallback(entries) {
        entries.forEach(function (entry) {
            if (entry.isIntersecting) {
                var texts = entry.target;
                texts.classList.add('animPlay');
                if(texts.classList.contains('progress')){
                    // stroke-dashoffset
                    texts.children[0].style.strokeDashoffset = texts.dataset.dashoffset;
                }
            }
        });
    }

    function obs(targets) {
        targets.forEach((target) => observer.observe(target));
    }

    let targets = Array.from(document.querySelectorAll('header .text-container,' +
        ' .text-container.exergue, ' +
        '.temoignages-container ' +
        '.text-container, ' +
        '.single-service .text-container,' +
        ' .engagement,' +
        ' .key-figures figure, ' +
        '.engagements .cta,' +
        'blockquote,' +
        'p.is-style-2-column,' +
        'wp-block-column p,' +
        'h1,' +
        'h2,' +
        'h3,' +
        '.sample-page article p,' +
        '.sample-page ul,' + 
        '.single-service section article .interventions .wrapper .tabs .tab .progress'));

    obs(targets);

    let observerChiffre = new IntersectionObserver(callback, options);

    function callback(entries) {

        entries.forEach(function (entry) {
            if (entry.isIntersecting) {
                observerChiffre.disconnect();

                var cont = {val: 0};
                var valArrivee = entry.target.innerText;

                TweenLite.to(cont, 1, {
                    val: valArrivee,
                    roundProps: "val",
                    onUpdate: function () {
                        entry.target.innerHTML = cont.val;
                    }
                });
            }


        });

    }

    function obs2(targets) {
        targets.forEach((target) => observerChiffre.observe(target));
    }

    let targetsFigure = Array.from(document.querySelectorAll('.figures .figure p span'));

    obs2(targetsFigure);

    let screenWidth = window.screen.width;
    let actuDesktop = document.getElementsByClassName('desktop-act');
    let actuMobile = document.getElementsByClassName('mobile-act');

    actuMbl(screenWidth,actuDesktop,actuMobile)

    function actuMbl(screenWidth, actuDesktop, actuMobile){
        if(screenWidth < 1024){
            if ( actuDesktop.length > 0 ) {
                actuDesktop[0].style.display = "none";
            }
            if ( actuMobile.length > 0 ) {
                actuMobile[0].style.display = "block";
            }

        }else{
            if ( actuDesktop.length > 0 ) {
                actuDesktop[0].style.display = "block";
            }
            if ( actuMobile.length > 0 ) {
                actuMobile[0].style.display = "none";
            }
        }
    }    
});
