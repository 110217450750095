import Swiper from 'swiper';
import {TweenMax, TweenLite} from 'gsap';
import Wheel from './../components/wheel';
import Testimonies from './../components/testimonies';

export default class Home {
    constructor() {
        // Page d'accueil

        if(window.innerWidth > 767){
            this.posSvg();
            this.header();
        }
        this.intro();
        this.social();

        new Testimonies();
        new Wheel();

        window.onresize = ( () => {
            if(window.innerWidth > 767){
                this.posSvg();
            }
        } );
    }

    posSvg() {
        const x = window.innerWidth / 3,
              y = (window.innerHeight - 99) / 2,
              h = 377,
              w = 383;

        let possvg = (window.pageYOffset / window.innerHeight * 34) * 25 + (x - w  * .5);

        TweenMax.to(
            '#myClip path',
            .1,
            {
                transform: 'translate(' + possvg + 'px, ' + (y - h * .5) + 'px)'
            }
        );
    }

    header() {
        TweenMax.to(
            document.querySelector('.split .left'),
            2,
            {
                width: '66%'
            }
        );

        TweenMax.to(
            document.querySelector('.split .right'),
            2,
            {
                width: '34%'
            }
        );
    }

    intro() {
        const homeSlider = new Swiper ('.slider .swiper-container', {
            loop: true,
            spaceBetween: 20,
            speed:1300,
            autoplay: {
                delay: 4000
            },
            navigation: {
                nextEl: '.slider .controls .next',
                prevEl: '.slider .controls .prev',
            },
        });
    }

    social() {
        const networkSlider = new Swiper ('.network .swiper-container', {
            loop: true,
            spaceBetween: 20,
            slidesPerView: 2.5,
            breakpoints: {
                767: {
                   slidesPerView: 1 
                }
            },
            navigation: {
                nextEl: '.controls .next-live',
                prevEl: '.controls .prev-live',
            },
        });
    }
}