export class Youtubevideo {
    constructor() {

        // youtube api
        function getVideoId() {
            if(document.querySelector('#player')){
                return document.querySelector('#player').dataset.id;
            }
            //return 'l-gQLqv9f4o';
        }

        function loadPlayer() {

            if (typeof (YT) == 'undefined' || typeof (YT.Player) == 'undefined') {

                var tag = document.createElement('script');
                tag.src = "https://www.youtube.com/iframe_api";

                var firstScriptTag = document.getElementsByTagName('script')[0];
                firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

                window.onYouTubePlayerAPIReady = function () {
                    onYouTubePlayer();

                };

            } else {
                onYouTubePlayer();
            }
        }

        loadPlayer();

        var player;

        function onYouTubePlayer() {
            player = new YT.Player('player', {
                height: '360',
                width: '880',
                videoId: getVideoId(),
                playerVars: {
                    controls: 1,
                    modestbranding: 1,
                    showinfo: 0,
                    rel: 0,
                    showsearch: 0,
                    iv_load_policy: 3
                }
            });
        }

        // lancé la video
        const youtubePoster = document.querySelector('.youtube-slider-poster');
        const youtubeButton = document.querySelector('.youtube-slider-button');

        if (youtubeButton){
            youtubeButton.addEventListener('click', function () {
                youtubePoster.classList.add('d-none');
                youtubeButton.classList.add('d-none');
                player.playVideo();
            })
        }
    }
}
