import TweenMax from 'gsap/src/uncompressed/TweenMax';

export default class Scroll {
    constructor() {
        // Module scroll
        if(document.querySelector('.split > div .scroll') != null){
            document.querySelector('.split > div .scroll').addEventListener('click', () => {
                window.scrollTo({
                    top: (window.innerHeight - document.querySelector('nav').offsetHeight),
                    behavior: 'smooth'
                });
            });
        }
    }

    init() {
        window.onscroll = () => {
            if(document.body.classList.contains('home') && window.innerWidth > 767){
                if(window.pageYOffset < window.innerHeight){
                    let transform = (window.pageYOffset / window.innerHeight * 34) + 66;

                    TweenMax.to(
                        '.split .left',
                        .5,
                        {
                            width: transform + '%'
                        }
                    );

                    TweenMax.to(
                        '.split .right',
                        .5,
                        {
                            width: (100 - transform) + '%'
                        }
                    );

                    const x = window.innerWidth / 3,
                          y = (window.innerHeight - 99) / 2,
                          h = 377,
                          w = 383;

                    let possvg = (window.pageYOffset / window.innerHeight * 34) * 25 + (x - w  * .5);

                    TweenMax.to(
                        '#myClip path',
                        .5,
                        {
                            transform: 'translate(' + possvg + 'px, ' + (y - h * .5) + 'px)'
                        }
                    );

                    document.querySelector('aside.sidebar').style.transform = 'translate(100%, 0)';
                } else {
                    document.querySelector('aside.sidebar').style.transform = 'translate(0%, 0)';
                }
            }
        };
    }
}