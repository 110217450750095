import {Timeline} from '../components/Timeline';
import {Video} from '../components/Video';

export default class Rte {
    constructor() {
        Array.from(document.querySelectorAll('.timeline'))
            .forEach(element => new Timeline(element));

        Array.from(document.querySelectorAll('.wp-block-video'))
            .forEach(element => new Video(element));
    }
}