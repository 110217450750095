import TweenMax from 'gsap/src/uncompressed/TweenMax';
import Swiper from 'swiper';

export default class Testimonies {
    constructor() {
        // Module Temoignages

        const sliderTemoignage = new Swiper('.home .temoignages', {
            slidesPerView: 5,
            slidesPerGroup: 5,
            slidesPerColumn: 2,
            spaceBetween: 20,
            slidesPerColumnFill: 'row',
            pagination: {
                el: '.testimonies .controls .pagination',
                clickable: true,
                type: 'fraction'
                // renderBullet(index, className) {
                //     return '<span class="' + className + '">' + (index + 1) + '</span>';
                // },
            },
            navigation: {
                nextEl: '.testimonies .controls .next',
                prevEl: '.testimonies .controls .prev',
            },
            breakpoints: {
                1023: {
                    slidesPerView: 3,
                    slidesPerGroup: 3,
                },
                767: {
                    slidesPerView: 1,
                    slidesPerGroup: 1,
                },
            },
            autoplay: {
                delay: 2000,
            },
            speed: 1530
        });

        const sliderTemoignageServices = new Swiper('.service-template-default .temoignages', {
            slidesPerView: 2,
            slidesPerGroup: 2,
            slidesPerColumn: 2,
            spaceBetween: 0,
            slidesPerColumnFill: 'row',
            pagination: {
                el: '.testimonies .controls .pagination',
                clickable: true,
                type: 'fraction'
                // renderBullet(index, className) {
                //     return '<span class="' + className + '">' + (index + 1) + '</span>';
                // },
            },
            navigation: {
                nextEl: '.testimonies .controls .next',
                prevEl: '.testimonies .controls .prev',
            },
            on: {
                init() {
                    if (this.slides.length) {
                        this.slides[0].classList.add('active');
                        document.querySelectorAll('.testimonies .testimonies-container .testimony')[0].classList.add('active');
                        document.querySelectorAll('.testimonies .testimonies-container-mobile .testimony')[0].classList.add('active');
                    }
                },
            },
            breakpoints: {
                1023: {
                    slidesPerView: 2,
                    slidesPerGroup: 2,
                },
                767: {
                    slidesPerView: 1,
                    slidesPerGroup: 1,
                },
            },
        });

        const sliderTemoignagemobile = new Swiper('.temoignages-mobile', {
            slidesPerView: 5,
            slidesPerGroup: 5,
            slidesPerColumn: 2,
            spaceBetween: 20,
            loop: true,
            slidesPerColumnFill: 'row',
            pagination: {
                el: '.testimonies .controls .pagination-mobile',
                clickable: true,
                type: 'fraction',
                renderBullet(index, className) {
                    return '<span class="' + className + '">' + (index + 1) + '</span>';
                },
            },
            autoplay: {
                delay: 2000,
            },
            navigation: {
                nextEl: '.testimonies .controls-mobile .next-mobile',
                prevEl: '.testimonies .controls-mobile .prev-mobile',
            },
            on: {
                init() {
                    if (this.slides.length) {
                        this.slides[0].classList.add('active');
                        document.querySelectorAll('.testimonies .testimonies-container .testimony')[0].classList.add('active');
                        document.querySelectorAll('.testimonies .testimonies-container-mobile .testimony')[0].classList.add('active');

                    }
                },
            },
            breakpoints: {
                1023: {
                    slidesPerView: 3,
                    slidesPerGroup: 3,
                },
                767: {
                    slidesPerView: 1,
                    slidesPerColumn: 1,
                    slidesPerGroup: 1,
                },
            },
        });

        if (document.querySelector('.service-template-default .testimonies')) {
            if (window.innerWidth < 767) {
                TweenMax.to(
                    '.service-template-default .testimonies .temoignages-mobile .swiper-wrapper .swiper-slide .testimonies-container-mobile',
                    .3,
                    {
                        height: document.querySelector('.testimonies .temoignages-mobile .swiper-wrapper .swiper-slide .testimonies-container-mobile .active').offsetHeight,
                    },
                );

                for (let i = 0; i < sliderTemoignagemobile.slides.length; i++) {
                    sliderTemoignagemobile.slides[i].addEventListener('click', (e) => {

                        this.manageTestimonies(e.currentTarget, i);
                    });
                }

                const Testimonies = this;
                sliderTemoignagemobile.on('slideChange', function () {
                    const index = sliderTemoignagemobile.activeIndex;
                    const element = sliderTemoignagemobile.slides[index];
                    Testimonies.manageTestimonies(element, 0);

                });


            } else {

                TweenMax.to(
                    '.service-template-default .testimonies .testimonies-container',
                    .3,
                    {
                        height: document.querySelector('.service-template-default .testimonies .testimonies-container .active').offsetHeight,
                    },
                );

                for (let i = 0; i < sliderTemoignageServices.slides.length; i++) {
                    sliderTemoignageServices.slides[i].addEventListener('click', (e) => {
                        this.manageTestimonies(e.currentTarget, i);

                    });
                }
            }
        }
    }



    manageTestimonies(elt, index) {

        const getAllSlides = document.querySelectorAll('.service-template-default .testimonies .temoignages .swiper-slide');
        const getAllSlidesMobile = document.querySelectorAll('.service-template-default .testimonies .temoignages .swiper-slide');

        if (window.innerWidth < 767) {
            const container = document.querySelectorAll('.service-template-default .testimonies .temoignages-mobile .swiper-wrapper .swiper-slide .testimonies-container-mobile')
            for (let j = 0; j < getAllSlidesMobile.length; j++) {
                getAllSlidesMobile[j].classList.remove('active');
                container[j].children[0].classList.remove('active');
            }

            elt.children[1].children[0].classList.add('active');
            elt.classList.add('active');

            TweenMax.to(
                container,
                .3,
                {
                    height: elt.children[1].children[0].offsetHeight,
                },
            );

        } else {

            for (let j = 0; j < getAllSlides.length; j++) {
                getAllSlides[j].classList.remove('active');
                document.querySelector('.service-template-default .testimonies .testimonies-container').children[j].classList.remove('active');

            }

            elt.classList.add('active');

            document.querySelector('.service-template-default .testimonies .testimonies-container').children[index].classList.add('active');

            TweenMax.to(
                '.service-template-default .testimonies .testimonies-container',
                .3,
                {
                    height: document.querySelector('.service-template-default .testimonies .testimonies-container').children[index].offsetHeight,
                },
            );
        }

    }

    getElementOffset(el) {
        const rect = el.getBoundingClientRect();

        return {
            top: rect.top + window.pageYOffset,
            left: rect.left + window.pageXOffset,
        };
    }
}
