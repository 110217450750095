export default class Contact {
    constructor() {
        let getInputs = Array.from(document.querySelectorAll('form fieldset input'));
        Array.from(document.querySelectorAll('form fieldset textarea')).forEach( element => {
            getInputs.push(element);
        });

        getInputs.forEach( elt => {
            if(elt.value != ''){
                elt.classList.add('filled');
            }

            elt.addEventListener('keyup', e => {
            	if(e.currentTarget.value != ''){
            		e.currentTarget.classList.add('filled');
            	} else {
            		e.currentTarget.classList.remove('filled');
            	}
            });
        });

        const formInputs = document.querySelectorAll('input[type="file"]');
        Array.from(formInputs).forEach( item => {
            item.addEventListener('change', this.editName);
        });

        const ctaButton = document.querySelector('form .cta');
        var form_being_submitted = false;
        var checkForm = function(e) {
            if(form_being_submitted) {
                ctaButton.disabled = true;
                e.preventDefault();
                return;
            }
            form_being_submitted = true;
        };

        const appForm = document.getElementById("app_form");
        if(appForm){
            appForm.addEventListener("submit", checkForm, false);
        }

        const contactForm = document.getElementById("contact_form");
        if(contactForm){
            contactForm.addEventListener("submit", checkForm, false);
        }

    }

    editName(input) {
        if (input.currentTarget.dataset.maxsize < input.currentTarget.files[0].size) {
            alert('Le fichier est trop volumineux');
            input.currentTarget.value = '';
        } else {
            input.currentTarget.nextElementSibling.innerHTML = input.currentTarget.files[0].name;
        }
    }
}